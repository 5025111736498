.previews {
  display: none;
}

.image-preview {
  width: 350px;
  max-width: 100%;
  display: block;
  margin-bottom: 8px;
}

.video-uploader {
  margin-top: 24px;
}

.MuiFormControl-marginNormal {
  margin-top: 0px !important;
  margin-bottom: 32px !important;
}
