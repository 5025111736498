body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.avatar-image,
.avatar-image-sm {
  display: flex;
  align-items: center;
}

.avatar-image-img,
.avatar-image img {
  border-radius: 999rem;
  max-height: 65px;
}

.avatar-image-sm img {
  border-radius: 999rem;
  max-height: 45px;
}

.avatar-collection-img {
  border-radius: 999rem;
  height: 200px;
}

#avatars a,
.column-avatars a {
  display: flex;
  align-items: center;
}

.interest-image {
  display: flex;
  align-items: center;
}

.interest-image img,
.interest-image-img {
  max-height: 60px;
}
